/* Email Confirmation Page Styles */
.email-confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
}

.code-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.code-input {
    width: 5%;
    max-width: 180px;
    height: 100px;
    font-size: 24px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
}

.code-input:focus {
    border-color: #0056b3;
}

.error-message {
    color: red;
    font-size: 11px;
    margin-top: 10px;
}

.loading-message {
    color: #0056b3;
    font-size: 11px;
    font-weight: bold;
    margin-top: 20px;
}

.resend-code {
    margin-top: 40px;
    padding: 10px 15px;
    font-size: 10px;
    /* color: white;
    background-color: #4430aa; */
    color: #4430aa;
    background-color:#ffffff00;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.resend-code:hover {
    color: #156dca;
}

.reset-signup-btn {
    background: none;
    border: none;
    color: #888;
    font-size: 9px;
    text-decoration: underline;
    margin-top: 25px;
    cursor: pointer
}

.reset-signup-btn:hover {
    color: #5c5c5c;
}

.modal-reset-signup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-reset-signup {
    background: #fff;
    padding: 20px 25px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    max-width: 350px;
    width: 100%;
}

.modal-reset-signup input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-reset-signup-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}


@media (max-width: 800px) {

    .code-input {
        width: 5%;
        height: 50px;
    }

  }