.container {
    top:0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    background-color: #fff;
    margin-top: 15px;
}

.overlay {
    width: 22%;
    padding: 50px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.signup-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 300px;
    padding-inline: -50px;
    padding-block: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    margin-top: -40px;
}

input {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #121212;
    font-size: 14px;
}

.title-section{
    display: flex;
    width: 100%;
    margin-bottom: -1px;
}

.password-container {
    position: relative;
    width: 100%;
    margin-left: -8px;
}

.toggle-password {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
}

.signup-btn {
    background-color: #4430aa;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
    border-radius: 10px;
}

.links {
    text-align: center;
    margin-top: 20px;
}

.links span {
    color: #0042aa;
    cursor: pointer;
}

label{
    font-size: 11px;
}

p{
    font-size: 11px;
}



@media (max-width: 800px) {

    .overlay {
        width: 70%;
        padding: 38px;
    }

    .password-container {
        margin-left: -6px;
    }

    .toggle-password {
        right: 3px;
    }

  }