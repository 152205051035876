.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    padding: 10px 10px;
    margin-top: 15px;
    background-color: #ffffff;
  }
  
  .header-left {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  
  .logo-container {
    cursor: pointer;
  }
  
  .logo-next-stock {
    width: 225px;
    height: 105px;
    margin:-20px;
    margin-top:-43px;
    margin-right:-91px;
    margin-left: -80px;
  }
  
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
  }
  
  .menu-icon {
    color: #4a10aa;
    margin-left: 20px;
  }
  
  .menu-text {
    font-size: 9px;
    color: #4a10aa;
    margin-top: 3px;
    margin-left: 19px;
  }

  .menu-icon2 {
    color: #4a10aa;
    width: 29px;
    margin-left: 20px;
  }

  .menu-text2 {
    font-size: 9px;
    color: #4a10aa;
    margin-top: 3px;
    margin-left: 20px;
  }

  span{
    font-size: 10px;
    color: #4a10aa;
    margin-top: 4px;
    margin-left: 20px;
  }
  
  .header-right {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .user-info {
    font-size: 12px;
    color: #666;
  }
  
  .user-email {
    font-weight: bold;
  }
  

  @media (max-width: 768px) {
    .header-left {
      gap: 0px;
    }
    .logo-next-stock {
      width: 210px;
      height: 100px;
      margin-right:-92.5px;
      margin-left: -82px;
    }
    .menu-icon {
        width: 26px;
        margin-left: 5.8px;
        margin-right: 5.8px;
      }
    .menu-text {
        font-size: 7px;
        margin-top: -2px;
        margin-left: 5.8px;
        margin-right: 5.8px;
    }
    .menu-icon2 {
      width: 26px;
      margin-left: 5.8px;
      margin-right: 5.8px;
    }
    .menu-text2 {
      font-size: 7px;
      margin-top: -1px;
      margin-bottom: 1px;
      margin-left: 0px;
      margin-right: -2px;
    }
  }

  
  @media (max-width: 300px) {

    .logo-next-stock {
      width: 145px;
      height: 65px;
      margin-right:-59px;
      margin-left: -75px;
    }
    .menu-icon {
        width: 20px;
        margin-left: 5.5px;
        margin-right: 5.5px;
      }
    .menu-text {
        font-size: 6px;
        margin-top: -4px;
        margin-left: 4px;
        margin-right: 4px;
    }
    .menu-icon2 {
      width: 20px;
      margin-left: 0px;
      margin-right: -2px;
    }
    .menu-text2 {
      font-size: 6px;
      margin-top: -3px;
      margin-bottom: 1px;
      margin-left: 1px;
      margin-right: 0px;
    }
  }
  