.container {
    top:0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    background-color: #fff;
    margin-top: 15px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top: 5px solid #0042aa;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.overlay {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 300px;
    margin-bottom: 40px;
}

input {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #121212;
    font-size: 14px;
}

.title-section{
    display: flex;
    width: 100%;
    margin-bottom: -1px;
}

.password-container {
    position: relative;
    width: 100%;
}


.login-btn {
    background-color: #0042aa;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 30px;
}

.google-btn {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(44, 44, 44);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(1, 1, 1, 0.2);
    border: none;
    cursor: pointer;
    width: 95%;
    margin-top: 0px;
    font-size: 13px;
}

.google-logo {
    width: 30px;
    height: auto;
    margin-bottom:-2px;
    margin-left: -4px;
    margin-right: 8px;
}

.facebook-btn {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(44, 44, 44);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(1, 1, 1, 0.2);
    border: none;
    cursor: pointer;
    width: 95%;
    margin-top: 12px;
    margin-bottom: 35px;
    font-size: 13px;
}

.facebook-logo {
    width: 25px;
    height: auto;
    margin-bottom: -5px;
    margin-right: 10px;
}

.links {
    text-align: center;
}

.links span {
    color: #0042aa;
    cursor: pointer;
}

.bottom-texts {
    color: #0042aa;
    font-size: 12px;
    margin-left: 2px;
    cursor: pointer;
}

@media (max-width: 800px) {

    .google-btn {
        padding: 18px;
        width: 90%;
        margin-top: 5px;
        font-size: 11px;
    }

    .facebook-btn {
        padding: 18px;
        width: 90%;
        margin-top: 10px;
        font-size: 11px;
    }

  }