.contact-container {
    text-align: center;
    padding: 20px;
}

.contact-content {
    width: 100%;
}

.contact-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: 20px auto;
    text-align: left;
}

.contact-header {
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-top: -30px;
}

.logo_contact {
    width: 220px;
    margin-inline: -85px;
    margin-bottom: -10px;
}

.title-text {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
}

.user-info {
    font-size: 12px;
    margin-bottom: -7px;
    color: #444;
}

.manage-subscription {
    font-size: 12px;
    margin-bottom: -7px;
    color: #0042aa;
    cursor: pointer;
    margin-bottom: 5px;
}

.reset-password {
    font-size: 11px;
    margin-bottom: -7px;
    color: #ff8282;
    cursor: pointer;
}

.user-info span {
    color: #0042aa;
    font-weight: bold;
}

label {
    display: block;
    font-size: 16px;
    margin-top: 10px;
    color: #3110aa;
}

input, textarea {
    width: 95%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #666;
    border-radius: 5px;
}

input {
    width: 95%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #666;
    border-radius: 5px;
}

textarea {
    height: 100px;
}

.send-btn {
    background-color: #4a10aa;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    width: 100%;
    margin-top: 15px;
}

.signin-btn {
    background: none;
    border: none;
    color: #0042aa;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.success-message {
    text-align: center;
    margin-top: 20px;
}

.success-message h3 {
    color: #3110aa;
    font-size: 20px;
    font-weight: 700;
}

.footer-text {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

.input-subject{
    font-size: 14px;
}

@media (max-width: 768px) {
    .title-text {
        margin-left: 20%;
        font-size: 15px;
        font-weight: 600;
    }
    .user-info {
        font-size: 9px;
    }
    span{
        font-size: 8px;
    }
}