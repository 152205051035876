.video-container {
    text-align: center;
    margin: 20px auto;
    width: 70%;
    position: relative;
}

.delete-btn {
    background-color: #f34848;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    top: -30px;
    right: 10px;
}
