.top10-container {
    text-align: center;
    padding: 20px;
}

.top10-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top10-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 100%;
    padding: 30px;
}

.top10-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.top10-title {
    font-size: 50px;
}

.top10-logo-next-stock {
    width: 90px;
    height: 42px;
    margin:-20px;
    margin-top:-8px;
    margin-right:-20px;
    margin-left: -10px;
  }

.top10-header h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
}

.top10-dropdowns {
    display: flex;
    gap: 10px;
}

.top10-selectors {
    display: flex;
    width: 60%;
    min-width: 310px;
    gap: 15px;
    margin-top: 10px;
    cursor: pointer;
}

.top10-selectors select {
    padding-block: 9px;
    padding-inline: 7px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
}

.top10-upgrade-btn {
    background-color: #4a10aa;
    color: white;
    padding: 14px;
    padding-inline: 30px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.top10-upgrade-btn svg {
    width: 16px;
    height: 16px;
}

.top10-list-container {
    flex: 1;
    width: 80%;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin-top: 20px;
}

.top10-list-container h3 {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.top10-list-container ul {
    list-style-type: none;
    padding: 0;
}

.top10-list-container li {
    padding: 12px;
    background: #f9f9f9;
    background: #fff;
    
}

.top10-cards-area {
    display: flex; /* <- This enables flexbox */
    flex-direction: row;
    align-items: flex-start; /* optional: aligns items to the top */
    justify-content: space-between; /* optional: space between text & image */
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    /* background-color: #eee; */
    padding: 15px; /* optional: add some padding */
    border-radius: 8px; /* optional: round corners */
    margin: 5px 0;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.top10-target-text-one {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px;
}

.top10-target-text-two {
    font-size: 12px;
    margin-bottom: 0px;
}

.top10-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.top10-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top: 5px solid #0042aa;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

H2{
    font-size: 42px;
    font-weight: 800;
    color: #4a10aa;
    margin-top: -20px;
}

H3{
    align-self: center;
    margin-top: 40px;
    font-size: 18px;
    color: #4a10aa;
}

@media (max-width: 400px) {
    .top10-box {
        width: 80%;
    }

    .top10-selectors {
        gap: 0px;
    }
    .top10-selectors select {
        margin: 8px;
        font-size: 13px;
        width: 90%;
    }
    .top10-title {
        margin-top: 5px;
        font-size: 20px;
    }
}