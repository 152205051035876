.youtube-container {
    text-align: center;
    padding: 20px;
}

.new-video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    width: 60%;
}

.new-video label {
    font-size: 12px;
    color: #666;
    margin-right: 10px;
}

.new-video input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    border: 1px solid #ddd;
    margin-right: 10px;
}

.add-btn {
    background-color: #4a10aa;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.video-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
