.porfifolio-title {
    font-size: 50px;
}

.porfifolio-selectors {
    display: flex;
    width: 90%;
    max-width: 370px;
    margin-top: 10px;
    cursor: pointer;
}

.porfifolio-selectors select {
    padding-block: 9px;
    padding-inline: 7px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
}

@media (max-width: 400px) {
    .porfifolio-title {
        margin-top: 5px;
        font-size: 20px;
    }

}